<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
  #container
>
  <div class="flex justify-between p-3">
    <div class="flex items-center overflow-hidden">
      <span
        class="pl-5 text-17 font-semibold text-md overflow-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ widget?.name }}
      </span>
      @if (widget?.description) {
        <fa-icon
          class="ml-2"
          [icon]="['far', 'circle-info']"
          [matTooltip]="widget?.description"
        ></fa-icon>
      }
    </div>
    <div class="flex items-center">
      @if (data?.totalHits > 0) {
        <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
          data?.totalHits | number
        }}</itq-pill>
        <itq-nav-button
          class="mr-1 ml-2"
          [padding]="Sizes['X-SM']"
          [width]="170"
          [openDirection]="{ vertical: 'down', horizontal: 'right' }"
          [openPosition]="'inside'"
          [template]="displayTypesTemplate"
          [templateData]="widget"
          [materialStyle]="'basic'"
          [matTooltipPosition]="'above'"
          [matTooltip]="'Change display type'"
        >
          @if (selectedDisplayType?.icon) {
            <fa-icon [size]="'lg'" [icon]="selectedDisplayType?.icon"></fa-icon>
          } @else {
            <itq-svg
              [icon]="selectedDisplayType?.svgIcon"
              class="w-6"
            ></itq-svg>
          }
        </itq-nav-button>
      }
      <itq-nav-button
        [padding]="Sizes['X-SM']"
        [width]="185"
        [openDirection]="{ vertical: 'down', horizontal: 'right' }"
        [openPosition]="'inside'"
        [template]="widgetMoreTemplate"
        [templateData]="widget"
        [materialStyle]="'basic'"
        [matTooltip]="'More options'"
        [matTooltipPosition]="'above'"
      >
        <fa-icon [icon]="['far', 'gear']" [size]="'lg'"></fa-icon>
      </itq-nav-button>
    </div>
  </div>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border"
    #chartContainer
  >
    @if (selectedDisplayType?.type === ChartType.TABLE) {
      <itq-table
        class="m-4"
        [tableData]="data?.series[0]?.data"
        [tableColumns]="tableColumns"
        [trackBy]="'tagId'"
      >
      </itq-table>
    } @else {
      @if (!data || data?.totalHits > 0) {
        <itq-chart
          class="p-4"
          #chart
          [widget]="widget"
          [data]="data"
          [action]="action"
          [segment]="segment"
          (updateRef)="onUpdateRef($event)"
          (drilldown)="onExplore($event)"
        ></itq-chart>
      } @else {
        <itq-panel-info
          [label]="'No results found!'"
          [message]="'Please update your settings and try again...'"
          [icon]="'exclamation-circle'"
        ></itq-panel-info>
      }
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

<ng-template #widgetMoreTemplate let-widget>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onEdit()"
  >
    <fa-icon [icon]="['far', 'pencil']"></fa-icon>
    Edit
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onExplore()"
  >
    <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
    Explore
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onDeleteWidget(widget)"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Remove
  </itq-mat-button>
  <itq-mat-button
    [padding]="Sizes['X-SM']"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (mouseDownEvent)="onExportWidget()"
  >
    <fa-icon [icon]="['far', 'download']"></fa-icon>
    Export
  </itq-mat-button>
</ng-template>

<ng-template #displayTypesTemplate>
  <div class="displayTypes__container">
    @for (item of displayTypes; track item.id) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [padding]="Sizes['X-SM']"
        [materialStyle]="'basic'"
        (clickEvent)="onChangeDisplay(item)"
      >
        <div
          [ngClass]="{
            'fill-primary': item.id === selectedDisplayType.id ? true : false,
            'text-primary': item.id === selectedDisplayType.id ? true : false,
            flex: true,
            'items-center': true
          }"
        >
          <div class="flex items-center">
            @if (item.icon) {
              <fa-icon class="mr-1" [icon]="item.icon"></fa-icon>
            } @else {
              <itq-svg [icon]="item.svgIcon" class="w-4 h-4 mr-1"></itq-svg>
            }
          </div>
          {{ item.tooltip }}
        </div>
      </itq-mat-button>
    }
  </div>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count }}
  </button>
</ng-template>
