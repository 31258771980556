import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  SharedService,
  TagCategory,
} from '@intorqa-ui/core';
import { NotificationWidget } from '@portal/notifications/models/notification-widget';
import {
  GroupNotification,
  Notification,
} from '@portal/notifications/models/notifications';
import { GroupByDatePipe } from '@portal/notifications/pipes/notifications.pipe';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { Timeline } from '@portal/shared/models/timeline';
import { UserService } from '@portal/shared/services/user.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { Subscription } from 'rxjs';
import { WidgetSettingsComponent } from '../../../widget-settings/widget-settings.component';
import { NotificationsExploreComponent } from '../notifications-explore/notifications-explore.component';
import { NotificationsTimelineComponent } from '../notifications-timeline/notifications-timeline.component';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';

@Component({
  selector: 'itq-notifications-hub',
  templateUrl: './notifications-hub.component.html',
  styleUrls: ['./notifications-hub.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    FAwesomeModule,
    GroupByDatePipe,
    NotificationsTimelineComponent,
  ],
  providers: [GroupByDatePipe, SharedService],
})
export class NotificationsHubComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() query: string;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );

  @Output() drilldown = new EventEmitter<{
    segment?: ISegment;
    notification: Notification;
  }>();

  @ViewChildren('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainers: QueryList<ViewContainerRef>;

  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  private subscriptions = new Subscription();

  constructor(
    readonly componentFactoryResolver: ComponentFactoryResolver,
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.bindCreateProfileSubscription();
    this.bindOtherMatchesSubscription();
    this.bindLoadNotificationsSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindLoadNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount:
              response.totalCount !== undefined
                ? response.totalCount
                : this.notificationsDataSource.totalCount,
          };
        },
      ),
    );
  }

  private bindOtherMatchesSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadOtherMatches$.subscribe(
        (params: { filters: QueryFilters; raisedAlertId: string }) => {
          this.loadOtherMatches(params);
        },
      ),
    );
  }

  private bindCreateProfileSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.createProfile$.subscribe(
        (params: {
          profileDrilldown: ProfileDrilldown;
          notification: Notification;
        }) => {
          this.createProfile(params);
        },
      ),
    );
  }

  private loadOtherMatches(params: {
    filters?: QueryFilters;
    raisedAlertId?: string;
  }): void {
    const widget = new NotificationWidget(
      undefined,
      undefined,
      AnalysisTypes.NOTIFICATION,
      'Notifications Hub',
      undefined,
      ChartType.NOTIFICATION,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
    );
    let inputs = {
      widget,
      raisedAlertId: params.raisedAlertId,
    };
    if (params.raisedAlertId) {
      inputs = {
        ...inputs,
        ...{
          type: this.form.get('type').value,
          query: this.form.get('query').value,
          showAll: this.form.get('showAll').value,
          priority: this.form.get('priority').value,
          initialState: this.initialState.cloneDeep(),
        },
      };
    }
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: NotificationsExploreComponent,
          inputs,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[1100px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.notificationsService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      }
    });
  }

  private createProfile(params: {
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      params.profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.userService.userPreferences.defaultEcosystemId,
      params.profileDrilldown.profileType.id,
      params.profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        params.profileDrilldown.value,
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? params.profileDrilldown.value
          : `Field field:${params.profileDrilldown.value}:${
              params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'md',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    switch (params.segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(params);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(params);
        break;
      default:
        break;
    }
  }

  private drilldownChannel(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      params.segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${params.segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(params: {
    segment?: ISegment;
    notification?: Notification;
  }): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      params.segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${params.segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
