@if (tagsDataSource) {
  <div class="flex flex-col h-full" [formGroup]="form">
    <div class="flex mb-2 flex-col flex-1 overflow-hidden">
      <span class="mb-1 text-13 font-medium">TAG</span>
      <itq-timeline-metrics-list
        [formControlName]="'dataSource'"
        [dataSource]="tagsDataSource"
        [dataFields]="{ name: 'name', value: 'id' }"
        [initialState]="initialState"
        (changeValue)="onChangeDataSources($event)"
        (search)="onGetTags()"
      ></itq-timeline-metrics-list>

      @if (
        form.controls['dataSource']?.touched &&
        form.controls['dataSource']?.hasError('required')
      ) {
        <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
      }
    </div>
    <div class="flex mb-2 flex-col">
      <span class="mb-1 text-13 font-medium">DATE RANGE</span>
      <itq-date-range
        [form]="form"
        [dates]="this.dates"
        (search)="onChangeDate($event)"
      ></itq-date-range>
    </div>
  </div>
}
