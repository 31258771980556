<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'boardHeader'">
    <div
      class="rounded-md border border-border bg-white flex w-full items-center justify-center p-7"
    >
      <div class="flex items-center flex-1">
        <fa-icon
          class="pr-2"
          [icon]="['far', 'chart-column']"
          [size]="'3x'"
        ></fa-icon>

        <itq-input
          class="board__name flex-1"
          [formControlName]="'name'"
          [matTooltip]="'Please click to edit'"
          [placeholder]="'Please type to add board name'"
          (blurEvent)="onUpdateName()"
          (mouseDownEvent)="$event.stopImmediatePropagation()"
        ></itq-input>
        @if (board?.defaultBoard) {
          <fa-icon
            class="ml-4"
            [icon]="['far', 'star']"
            class="text-primary"
            [size]="'lg'"
          ></fa-icon>
        } @else {
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Set as default'"
            (clickEvent)="onToggleDefault()"
          >
            <fa-icon [icon]="['far', 'star']" [size]="'lg'"></fa-icon>
          </itq-mat-button>
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Delete Board'"
            (clickEvent)="onDeleteBoard()"
          >
            <fa-icon [icon]="['far', 'trash-alt']" [size]="'lg'"></fa-icon>
          </itq-mat-button>
        }
      </div>
      @if (!editingDescription) {
        <div
          [ngStyle]="{ minHeight: '36px' }"
          [matTooltip]="'Please click to edit'"
          class="hover:border hover:border-border mx-2 rounded-md flex-1 ml-4 line-clamp-3 items-center flex"
          (click)="onEditDescription()"
        >
          @if (form?.get('boardHeader.description')?.value) {
            <span class="items-center p-2 line-clamp-3 max-h-[75px]">
              {{ form?.get('boardHeader.description')?.value }}
            </span>
          } @else {
            <span class="items-center text-gray-500 text-opacity-75 p-2 flex">
              Please type to add board description
            </span>
          }
        </div>
      }
      <itq-text-area
        [rows]="3"
        [name]="'description'"
        [ngClass]="{
          'flex-1 board__description': true,
          visible: editingDescription,
          'invisible absolute': !editingDescription
        }"
        [formControlName]="'description'"
        placeholder="Please type to add board description"
        (blur)="onUpdateDescription(); editingDescription = false"
        (mousedown)="$event.stopImmediatePropagation()"
      ></itq-text-area>
    </div>
  </ng-container>
</ng-container>
