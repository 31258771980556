<div class="flex justify-between w-full">
  <div class="flex">
    @if (notification.tagEdited) {
      <div class="flex items-center mr-4">
        <fa-icon
          [size]="'2xl'"
          [matTooltip]="'This tag has changed since the alert was flat'"
          [icon]="['far', 'circle-exclamation']"
          [ngStyle]="{ color: '#ffb74d' }"
        ></fa-icon>
      </div>
    }
    <div class="flex items-center">
      <div
        class="rounded-2xl p-2 shadow-none font-semibold h-6 flex items-center"
        [ngStyle]="{
          backgroundColor: PriorityColor[this.notification.priority],
          width: '140px'
        }"
      >
        <fa-icon [icon]="['far', 'bell']"></fa-icon>
        <span class="pl-2 text-xs">{{
          AlertTypesLabel[notification.alertTypeName]
        }}</span>
      </div>
    </div>
    <div class="flex flex-col px-4 text-xs">
      <div class="flex font-semibold text-sm">
        {{ notification?.tagName }} has matched
        {{ Condition_Notification[notification?.condition] }}
        {{ notification?.count }} in
        {{ notification?.period | getPeriodLabel }}
      </div>
      @if (notification.message) {
        <div class="text-metadata">{{ notification.message }}</div>
      }
    </div>
  </div>
  <div class="flex">
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      [size]="Sizes.SM"
      [disabled]="
        notification?.ecosystemId !==
        this.userService.userPreferences.defaultEcosystemId
      "
      [matTooltip]="
        notification?.ecosystemId !==
        this.userService.userPreferences.defaultEcosystemId
          ? 'This tag belongs to a different ecosystem'
          : undefined
      "
      [padding]="Sizes['X-SM']"
      [matTooltip]="'Explore tag'"
      (clickEvent)="onExploreTag()"
    >
      <fa-icon [icon]="['far', 'expand']"></fa-icon>
    </itq-mat-button>
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'basic'"
      [size]="Sizes.SM"
      [padding]="Sizes['X-SM']"
      [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
      (clickEvent)="onToggleRead()"
    >
      <fa-icon [icon]="['far', notification.read ? 'check' : 'eye']"></fa-icon>
    </itq-mat-button>
  </div>
</div>
