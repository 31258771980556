import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { ApiConfigService } from '@intorqa-ui/api';
import {
  CustomOverlayService,
  DropdownRequest,
  OpenStatus,
  PortalBridgeService,
  QueryFilters,
  SharedService,
} from '@intorqa-ui/core';
import * as jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import posthog from 'posthog-js';
import { Observable, Subscription, filter, fromEvent } from 'rxjs';
import { environment } from '../environments/environment';
import { NotificationsService } from './notifications/services/notifications.service';

@Component({
  selector: 'itq-portal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  portal: Observable<TemplatePortal>;
  open = false;
  dropdownPosition = { left: 0, top: 0 };
  public version: string = environment.version;
  public showLoader = true;
  public showTooltip: boolean;
  public tooltipPosition: { top: number; left: number };
  public tooltipContent: string;
  public showNotifications = false;
  public raisedAlertId: string;
  public researchAlertId: string;
  private subscriptions = new Subscription();

  @ViewChild('backdrop') backdrop: ElementRef;

  constructor(
    private portalBridge: PortalBridgeService,
    public sharedService: SharedService,
    private keycloakService: KeycloakService,
    readonly customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    readonly activatedRoute: ActivatedRoute,
    @Inject(ApiConfigService) private config,
  ) {}

  ngOnInit(): void {
    this.bindHideNotificationsSubscription();
    this.bindLoaderSubscription();
    this.intializePosthog();
    this.bindRoutingSubscription();
    this.bindTooltipSubscription();
    this.bindDropdownSubscription();
    this.bindNotificationsSubscription();
    this.portal = this.portalBridge._portal;
  }

  private bindNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.openNotifications$.subscribe(
        (response: { raisedAlertId: string; researchAlertId: string }) => {
          this.onLoadNotifications(
            undefined,
            response.raisedAlertId,
            response.researchAlertId,
          );
        },
      ),
    );
  }

  private bindDropdownSubscription(): void {
    this.subscriptions.add(
      this.portalBridge.dropdownObeservable.subscribe(
        (args: DropdownRequest) => {
          if (args.position) {
            this.dropdownPosition = {
              top: args.position.top - 10,
              left: args.position.left + 10,
            };
          }
          switch (args.action) {
            case OpenStatus.CLOSE:
              this.open = false;
              break;
            case OpenStatus.OPEN:
              this.open = true;
              break;
            default:
              this.open = !this.open;
              break;
          }
        },
      ),
    );
  }

  private bindTooltipSubscription(): void {
    this.subscriptions.add(
      this.portalBridge.tooltip$.subscribe(
        (response: {
          show: boolean;
          top: number;
          left: number;
          content: string;
        }) => {
          this.showTooltip = response.show;
          this.tooltipPosition = {
            left: response.left,
            top: response.top,
          };
          this.tooltipContent = response.content;
        },
      ),
    );
  }

  private bindRoutingSubscription(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationStart ||
              event instanceof NavigationEnd,
          ),
        )
        .subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.showLoader = true;
          } else {
            this.showLoader = false;
          }
        }),
    );
  }

  private intializePosthog(): void {
    if (this.config?.posthog?.apiKey && this.config?.posthog?.isEnabled) {
      posthog.init(this.config?.posthog?.apiKey, {
        api_host: this.config?.posthog?.apiHost,
      });
    }

    this.keycloakService.loadUserProfile().then((response: KeycloakProfile) => {
      if (posthog && this.config?.posthog?.isEnabled) {
        posthog.identify(response.id);
        this.keycloakService.getToken().then((token: string) => {
          const decodedToken = jwt_decode.jwtDecode(token) as any;
          const organisation = decodedToken.organisation;
          posthog.people.set({
            email: response.email,
            organisation,
            login: new Date().toISOString(),
          });
        });
      }
    });
  }

  private bindLoaderSubscription(): void {
    this.subscriptions.add(
      this.sharedService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  bindHideNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.hideNotifications$.subscribe(() => {
        this.showNotifications = false;
      }),
    );
  }

  bindBackdropClick(): void {
    const click = fromEvent(this.backdrop.nativeElement, 'click');
    this.subscriptions.add(
      click.subscribe((response: PointerEvent) => {
        if (
          response.target instanceof HTMLElement &&
          response.target.classList.contains('notifications__backdrop')
        ) {
          this.showNotifications = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onLoadNotifications(
    event?: MouseEvent,
    raisedAlertId?: string,
    researchAlertId?: string,
  ): void {
    if (this.showNotifications) {
      this.showNotifications = false;
      this.raisedAlertId = undefined;
      this.researchAlertId = undefined;
      this.researchAlertId = undefined;
    } else {
      this.raisedAlertId = raisedAlertId;
      this.researchAlertId = researchAlertId;
      event?.stopPropagation();
      this.showNotifications = true;
      this.cdr.detectChanges();
      this.bindBackdropClick();
    }
  }

  public onCloseNotifications(): void {
    this.showNotifications = false;
  }
}
