@for (item of dataSource?.items; track item.date) {
  <div class="notification__container">
    <span class="notification__group">
      {{ item.date }}
    </span>
    <div class="flex flex-col overflow-auto">
      @for (
        notification of item.notifications;
        track notification.raisedAlertId
      ) {
        <div
          [ngClass]="{
            active: notification.raisedAlertId === raisedAlertId,
            'ml-4 mr-4 mb-4 notification__wrapper': true
          }"
        >
          <ng-container #dynamicComponentContainer></ng-container>
        </div>
      }
    </div>
  </div>
}
@if (dataSource?.totalCount === 0) {
  <itq-panel-info
    class="m-4"
    [label]="'Your inbox is empty!'"
    [message]="
      'When new notifications are available there will be shown in here'
    "
    [icon]="'exclamation-circle'"
  ></itq-panel-info>
}
