@if (notification) {
  <itq-mat-button
    class="w-[350px] m-4"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [materialStyle]="'stroked'"
    (clickEvent)="onLoadNotifications()"
  >
    <span class="text-wrapper">SEARCH NOTIFICATIONS</span>
  </itq-mat-button>
  <itq-notifications-research
    class="h-full"
    [notification]="notification"
    [form]="form"
    [showDocument]="true"
  ></itq-notifications-research>
}
