import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  EventBusService,
  FAwesomeModule,
  IError,
  SharedService,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { IBoard, ITree } from '@portal/boards/interfaces/board.interface';
import { IGroup } from '@portal/boards/interfaces/group.interface';
import { ISyncFusionTreeNode } from '@portal/boards/interfaces/tree.interface';
import { BoardService } from '@portal/boards/services/board.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { UserService } from '@portal/shared/services/user.service';
import {
  DragAndDropEventArgs,
  NodeEditEventArgs,
  TreeViewComponent,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { BoardSettingsComponent } from '../board-settings/board-settings.component';
import {
  AdaptTreeDataSourcePipe,
  ContainsDefaultBoardPipe,
} from './board-navigation.pipe';
import { ModalContainerComponent } from '../modal-container/modal-container.component';

@Component({
  selector: 'itq-board-navigation',
  templateUrl: './board-navigation.component.html',
  styleUrls: ['./board-navigation.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FormsModule,
    TreeViewModule,
    CommonModule,
    FAwesomeModule,
    AdaptTreeDataSourcePipe,
    SvgComponent,
    ContainsDefaultBoardPipe,
  ],
})
export class BoardNavigationComponent implements OnInit {
  @ViewChild('treeviewObj', { static: true }) treeObj: TreeViewComponent;
  @ViewChild('addTemplate') addTemplate: TemplateRef<unknown>;
  @ViewChild('groupMoreTemplate') groupMoreTemplate: TemplateRef<unknown>;

  public groupName = 'New group';
  public dataset: Array<ISyncFusionTreeNode> = [];
  public query = '';
  public isDragging = false;
  public dataSource: Array<ISyncFusionTreeNode>;
  private subscriptions = new Subscription();

  readonly Sizes = Sizes;
  readonly Align = Align;

  constructor(
    public boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    private snackBar: MatSnackBar,
    readonly keycloakService: KeycloakService,
    readonly sharedService: SharedService,
    readonly eventBusService: EventBusService,
    private router: Router,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.loadTree();
    this.bindLoadTreeSubscription();
    this.bindChangeEcosystemSubscription();
    this.loadUserInfo().then(() => {
      this.registerEventBusEvents();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.sharedService.changeEcosystem$.subscribe(() => {
        this.sharedService.loader$.next(true);
        this.unRegisterEventBusEvents();
        this.registerEventBusEvents();
        this.loadTree().then(() => {
          if (this.router.url === '/boards') {
            this.boardService.loadBoard$.next();
          } else {
            // Navigate to /boards if on a different route
            this.router.navigateByUrl('/boards');
          }
        });
      }),
    );
  }

  private loadUserInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.keycloakService
        .getKeycloakInstance()
        .loadUserInfo()
        .then((userInfo: any) => {
          this.userService.userInfo = userInfo;
          resolve();
        });
    });
  }

  private registerEventBusEvents(): void {
    this.registerEventBusTree();
    this.registerEventBusUpdateBoard();
    this.registerEventBusCreateBoard();
    this.registerEventBusDeleteBoard();
    this.registerEventBusCreateGroup();
    this.registerEventBusUpdateGroup();
    this.registerEventBusDeleteGroup();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusTree();
    this.unRegisterEventBusUpdateBoard();
    this.unRegisterEventBusDeleteBoard();
    this.unRegisterEventBusCreateBoard();
    this.unRegisterEventBusCreateGroup();
    this.unRegisterEventBusUpdateGroup();
    this.unRegisterEventBusDeleteGroup();
  }

  private bindLoadTreeSubscription(): void {
    this.subscriptions.add(
      this.boardService.loadTree$.subscribe(() => {
        this.setTreeData();
      }),
    );
  }

  private loadTree(): Promise<void> {
    return new Promise((resolve) => {
      this.boardService
        .getTree(this.userService.userPreferences.defaultEcosystemId)
        .then(() => {
          if (this.boardService.board?.id) {
            this.setTreeData();
            resolve();
          }
        });
    });
  }

  private setTreeData(): void {
    if (this.boardService.board?.id) {
      this.dataSource = this.boardService.getTreeShape(
        this.boardService.tree,
        this.boardService.board.id,
      );
    }
  }

  onCreateGroup(): void {
    this.boardService
      .createGroup(
        'New group',
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IGroup) => {
        const item = {
          id: response.uuid,
          name: response.name,
          hasChildren: false,
          child: [],
          isGroup: true,
          icon: 'FOLDER',
          defaultBoard: false,
        };
        this.dataSource.push(item);
        this.treeObj.addNodes([item]);
        this.editNode(item.id);
      });
  }

  public onCreateBoard(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: BoardSettingsComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[900px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDeleteGroup(node: ISyncFusionTreeNode): void {
    const board = this.boardService.boards.find((item: IBoard) => {
      return item.id === node.id;
    });
    if (board?.defaultBoard) {
      this.customOverlayService.openCustom(
        {
          title: 'Delete group?',
          message: `'<strong>${node.name}</strong>' can't be delete because it contains your default board`,
          icon: ['far', 'question-circle'],
          size: '4x',
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.boardService
              .deleteGroup(node.id)
              .then(() => {
                this.snackBar.open('Your group has been deleted!', 'Close', {
                  horizontalPosition: 'right',
                  duration: 5000,
                  verticalPosition: 'top',
                });
              })
              .catch((error: IError) => {
                this.showErrorNotification(error);
              });
          }
        },
      );
    } else {
      if (node.child?.length > 0) {
        this.customOverlayService.openCustom(
          {
            title: 'Delete group?',
            message: `By deleting this group you will <span class="label-danger"><strong>delete ${
              node.child?.length
            } board${
              node.child?.length > 1 ? 's' : ''
            }</strong></span> - Do you want to go ahead?`,
            icon: ['far', 'question-circle'],
            size: '4x',
            dialog: {
              type: DialogTypes.CONFIRM,
            },
          },
          DialogComponent,
          (result: boolean) => {
            if (result === true) {
              this.boardService
                .deleteGroup(node.id)
                .then(() => {
                  const board = this.boardService.getDefault();
                  this.router.navigateByUrl(`/boards/${board.id}`);
                  this.snackBar.open('Your group has been deleted!', 'Close', {
                    horizontalPosition: 'right',
                    duration: 5000,
                    verticalPosition: 'top',
                  });
                })
                .catch((error: IError) => {
                  this.showErrorNotification(error);
                });
            }
          },
        );
      } else {
        this.boardService
          .deleteGroup(node.id)
          .then(() => {
            this.snackBar.open('Your group has been deleted!', 'Close', {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            });
          })
          .catch((error: IError) => {
            this.showErrorNotification(error);
          });
      }
    }
  }

  private showErrorNotification(error: IError): void {
    this.customOverlayService.openCustom(
      {
        title: 'Oooops!',
        message: error.description,
        icon: ['far', 'exclamation-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.ALERT,
        },
      },
      DialogComponent,
    );
  }

  public renameGroup(node: any): void {
    this.editNode(node.id);
  }

  private editNode(id: string): void {
    setTimeout(() => {
      this.treeObj.beginEdit(id);
    }, 10);
  }

  public onRenameBoard(node: ISyncFusionTreeNode): void {
    this.editNode(node.id);
  }

  public onSetAsDefault(node: ISyncFusionTreeNode): void {
    this.boardService
      .updateBoard(node.id, {
        default: true,
      })
      .subscribe();
  }

  public nodeDrag(args: DragAndDropEventArgs): void {
    const dropNodeData = this.treeObj.getTreeData(
      args.droppedNodeData?.id?.toString(),
    ) as any;
    const draggedNodeData = this.treeObj.getTreeData(
      args.draggedNodeData?.id?.toString(),
    ) as any;

    if (
      args.droppedNodeData?.expanded &&
      args.dropLevel === 1 &&
      args.position === 'After'
    ) {
      args.dropIndicator = 'e-no-drop';
      const node: any = document.getElementsByClassName('e-sibling')[0];
      if (node) {
        node.style.display = 'none';
      }
    }
    if (args.draggedNodeData.hasChildren && args.dropLevel > 1) {
      args.dropIndicator = 'e-no-drop';
    }
    if (args.dropLevel > 2) {
      args.dropIndicator = 'e-no-drop';
    }
    if (args.dropLevel === 2 && draggedNodeData[0].isGroup) {
      args.dropIndicator = 'e-no-drop';
      const node: any = document.querySelector(
        "[data-uid='" + args.droppedNodeData.id + "']",
      );
      if (node && node.getElementsByClassName('e-sibling')[0]) {
        node.getElementsByClassName('e-sibling')[0].style.display = 'none';
      }
    }

    if (!dropNodeData[0]?.isGroup && args.position === 'Inside') {
      args.dropIndicator = 'e-no-drop';
    }
    if (
      args.draggedNodeData?.parentID === args.droppedNodeData?.id &&
      args.position === 'After'
    ) {
      const node: any = document.querySelector(
        "[data-uid='" + args.draggedNodeData.parentID + "']",
      );
      if (node && node.getElementsByClassName('e-sibling')[0]) {
        node.getElementsByClassName('e-sibling')[0].style.display = 'none';
        args.dropIndicator = 'e-no-drop';
      }
    }
    if (
      args.draggedNodeData?.parentID === args.droppedNodeData?.id &&
      args.droppedNodeData?.id &&
      args.position === 'Inside'
    ) {
      args.dropIndicator = 'e-no-drop';
    } else {
      if (
        dropNodeData[0]?.isGroup &&
        args.dropIndicator === 'e-drop-in' &&
        draggedNodeData[0].isGroup
      ) {
        args.dropIndicator = 'e-no-drop';
      }
    }
  }

  public dragStop(args: DragAndDropEventArgs): void {
    if (args.dropIndicator === 'e-no-drop') {
      args.cancel = true;
      this.isDragging = false;
    }
    this.isDragging = false;
  }

  public nodeDropped(): void {
    const tree = this.treeObj.getTreeData();
    this.boardService
      .updateTree(tree, this.userService.userPreferences.defaultEcosystemId)
      .catch((error: IError) => {
        this.showErrorNotification(error);
      });
  }

  public dragStart(args: DragAndDropEventArgs): void {
    args.event.stopImmediatePropagation();
    this.isDragging = true;
    const draggedNodeData = this.treeObj.getTreeData(
      args.draggedNodeData?.id?.toString(),
    ) as any;
    if (draggedNodeData[0].isGroup) {
      this.treeObj.collapseAll();
    }
  }

  public onLoadBoard(node: any): void {
    if (this.boardService.board.id !== node.nodeData.id) {
      this.router.navigateByUrl(`/boards/${node.nodeData.id}`);
    }
  }

  public onSelecting(args: any): void {
    const group = this.boardService.findGroupById(args.nodeData.id);
    if (group) {
      args.cancel = true;
    }
  }

  public onCreate(): void {
    const treeElement: any = this.treeObj.element;
    const instances: any = treeElement.ej2_instances;
    for (let i = 0; i < instances.length; i++) {
      if (instances[i].getModuleName() === 'draggable') {
        instances[i].dragArea = '.board__navigation_container';
        break;
      }
    }
  }

  public onDeleteBoard(node: ISyncFusionTreeNode): void {
    const board = this.boardService.findBoardById(node.id);
    this.customOverlayService.openCustom(
      {
        title: 'Delete board?',
        message: `Are you sure you want to permanently delete the ${board?.name} board?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.boardService.deleteBoard(board.id).subscribe(() => {
            this.snackBar.open('Your board has been deleted!', 'Close', {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            });
          });
        }
      },
    );
  }

  public onEditingNode(): void {
    this.boardService.preventUpdate = true;
  }

  private removeNode(args: NodeEditEventArgs): void {
    this.dataSource = this.dataSource.filter((item: ISyncFusionTreeNode) => {
      return item.id !== args.nodeData.id;
    });
    this.treeObj.removeNodes([args.nodeData.id.toString()]);
  }

  public onEditedNode(args: NodeEditEventArgs): void {
    if (args.newText?.trim() === '') {
      args.cancel = true;
      this.isDragging = false;
      if (args.newText.trim() === args.oldText.trim()) {
        this.removeNode(args);
      }
    } else {
      const node = this.treeObj.getTreeData(
        args?.nodeData?.id?.toString(),
      ) as any;
      if (node[0]?.isGroup) {
        this.boardService.updateGroup({
          uuid: node[0]?.id,
          name: args.newText,
        });
      } else {
        this.boardService
          .updateBoard(node[0]?.id, {
            name: args.newText,
          })
          .subscribe();
      }
    }
    this.boardService.preventUpdate = false;
  }

  private registerEventBusCreateGroup(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.CREATE_GROUP}`,
      this.createGroupCallback(),
    );
  }

  private unRegisterEventBusCreateGroup(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.CREATE_GROUP}`,
      this.createGroupCallback(),
    );
  }

  private registerEventBusUpdateGroup(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_GROUP}`,
      this.updateGroupCallback(),
    );
  }

  private unRegisterEventBusUpdateGroup(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_GROUP}`,
      this.updateGroupCallback(),
    );
  }

  private registerEventBusDeleteGroup(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.DELETE_GROUP}`,
      this.deleteGroupCallback(),
    );
  }

  private unRegisterEventBusDeleteGroup(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.DELETE_GROUP}`,
      this.deleteGroupCallback(),
    );
  }

  private registerEventBusUpdateBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_BOARD}`,
      this.updateBoardCallback(),
    );
  }

  private unRegisterEventBusUpdateBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_BOARD}`,
      this.updateBoardCallback(),
    );
  }

  private registerEventBusCreateBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.CREATE_BOARD}`,
      this.createBoardCallback(),
    );
  }

  private unRegisterEventBusCreateBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.CREATE_BOARD}`,
      this.createBoardCallback(),
    );
  }

  private registerEventBusDeleteBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.DELETE_BOARD}`,
      this.deleteBoardCallback(),
    );
  }

  private unRegisterEventBusDeleteBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.DELETE_BOARD}`,
      this.deleteBoardCallback(),
    );
  }

  private registerEventBusTree(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_TREE}`,
      this.treeUpdateCallback(),
    );
  }

  private unRegisterEventBusTree(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${this.userService.userInfo.organisationKey}.${this.userService.userPreferences.defaultEcosystemId}.${EventBusScope.UPDATE_TREE}`,
      this.treeUpdateCallback(),
    );
  }

  private updateBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard; tree: ITree };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard; tree: ITree };
        address: string;
        type: string;
      },
    ) => {
      this.boardService.updateTreeData(msg.body.tree);
      this.setTreeData();
    };
  }

  private deleteGroupCallback(): (
    err: Error,
    msg: {
      body: { groupId: string; tree: ITree };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { groupId: string; tree: ITree };
        address: string;
        type: string;
      },
    ) => {
      this.boardService.updateTreeData(msg.body.tree);
      const treeViewInstance = (document.getElementById('treeelement') as any)
        .ej2_instances[0];
      treeViewInstance.removeNodes([msg.body.groupId]);
      this.dataSource = this.dataSource.filter(
        (item: ISyncFusionTreeNode) => item.id !== msg.body.groupId,
      );
    };
  }

  private updateGroupCallback(): (
    err: Error,
    msg: {
      body: { group: IGroup; tree: ITree };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { group: IGroup; tree: ITree };
        address: string;
        type: string;
      },
    ) => {
      this.boardService.updateTreeData(msg.body.tree);
      this.setTreeData();
    };
  }

  private createGroupCallback(): (
    err: Error,
    msg: {
      body: { group: IGroup; tree: ITree; username: string };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { group: IGroup; tree: ITree; username: string };
        address: string;
        type: string;
      },
    ) => {
      this.registerEventBusCreateGroup();
      this.boardService.updateTreeData(msg.body.tree);
      if (msg.body.username !== this.keycloakService.getUsername()) {
        const treeViewInstance = (document.getElementById('treeelement') as any)
          .ej2_instances[0];
        const newNode: ISyncFusionTreeNode = {
          id: msg.body.group.uuid,
          name: msg.body.group.name,
          hasChildren: false,
          expanded: false,
          child: [],
          isGroup: true,
          icon: 'FOLDER',
          isSelected: false,
          defaultBoard: false,
        };
        treeViewInstance.addNodes([newNode], null, null);
        this.dataSource.push(newNode);
        this.treeObj.element.scrollTop = this.treeObj.element.scrollHeight;
      }
    };
  }

  private createBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard; tree: ITree; username: string };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard; tree: ITree; username: string };
        address: string;
        type: string;
      },
    ) => {
      this.boardService.updateTreeData(msg.body.tree);
      const newNode: ISyncFusionTreeNode = {
        id: msg.body.board.id,
        name: msg.body.board.name,
        hasChildren: false,
        expanded: false,
        child: [],
        isGroup: false,
        icon: 'WIDGET',
        isSelected:
          msg.body.username === this.keycloakService.getUsername()
            ? true
            : false,
        defaultBoard: msg.body.board.defaultBoard,
      };
      const treeViewInstance = (document.getElementById('treeelement') as any)
        .ej2_instances[0];
      treeViewInstance.addNodes([newNode], null, null);
      this.dataSource.push(newNode);
      this.treeObj.element.scrollTop = this.treeObj.element.scrollHeight;
    };
  }

  private deleteBoardCallback(): (
    err: Error,
    msg: {
      body: { boardId: string; tree: ITree };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { boardId: string; tree: ITree };
        address: string;
        type: string;
      },
    ) => {
      this.boardService.updateTreeData(msg.body.tree);

      // Remove the node from the tree view instance
      const treeViewInstance = (document.getElementById('treeelement') as any)
        .ej2_instances[0];
      const nodeToRemove = treeViewInstance.element.querySelector(
        '[data-uid="' + msg.body.boardId + '"]',
      );
      if (nodeToRemove) {
        treeViewInstance.removeNodes([nodeToRemove.getAttribute('data-uid')]);
      }

      // Update the dataSource without reassigning it
      const index = this.dataSource.findIndex(
        (item: ISyncFusionTreeNode) => item.id === msg.body.boardId,
      );
      if (index !== -1) {
        this.dataSource.splice(index, 1);
      }

      if (this.boardService.board.id === msg.body.boardId) {
        const board = this.boardService.getDefault();
        const node = treeViewInstance.element.querySelector(
          '[data-uid="' + board.id + '"]',
        );

        if (node) {
          treeViewInstance.selectNode(node);
        }
      }
    };
  }

  private treeUpdateCallback(): (
    err: Error,
    msg: { body: ITree; address: string; type: string },
  ) => void {
    return (
      err: Error,
      msg: { body: ITree; address: string; type: string },
    ) => {
      this.boardService.updateTreeData(msg.body);
      this.boardService.loadTree$.next();
    };
  }
}
