<form [formGroup]="form" class="flex h-full">
  @if (!raisedAlertId && !researchAlertId) {
    <div class="flex w-full">
      <div
        class="flex justify-between flex-col p-4 bg-white border-r border-border"
      >
        <div class="flex flex-col h-full" [ngStyle]="{ width: '250px' }">
          <span class="font-bold text-28 pb-7">Alerts</span>
          <div class="flex mb-2 flex-col">
            <span class="mb-1 text-13 font-medium">TYPE</span>
            <itq-dropdown
              formControlName="type"
              [clearSelection]="false"
              [httpBinding]="false"
              [dataSource]="typesDataSource"
              [clearSelection]="true"
              [dataFields]="{ name: 'label', value: 'id' }"
              [placeholder]="'Please select an item...'"
              (changeValue)="onGetNotifications()"
              (clear)="onGetNotifications()"
            ></itq-dropdown>
            <span class="text-13 text-metadata pt-1">
              Select type of alert you want</span
            >
          </div>
          <div class="flex mb-2 flex-col">
            <span class="mb-1 text-13 font-medium">PRIORITY</span>
            <itq-dropdown
              formControlName="priority"
              [clearSelection]="false"
              [clearSelection]="true"
              [httpBinding]="false"
              [dataSource]="priorityDataSource"
              [dataFields]="{ name: 'name', value: 'value' }"
              [placeholder]="'Please select an item...'"
              (changeValue)="onGetNotifications()"
              (clear)="onGetNotifications()"
            ></itq-dropdown>

            <span class="text-13 text-metadata pt-1">
              Select priority of the alert</span
            >
          </div>
          <div class="flex mb-2 flex-col">
            <span class="mb-1 text-13 font-medium">DATE</span>

            <itq-date-range
              [form]="form"
              class="w-full"
              [dates]="this.initialState?.where"
              (search)="onChangeDates($event)"
            ></itq-date-range>

            <span class="text-13 text-metadata pt-1"> Select date range</span>
          </div>
        </div>
        <div class="flex w-full">
          <itq-mat-button
            class="w-full"
            [disabled]="!notificationsCount || notificationsCount === 0"
            [size]="Sizes.BLOCK"
            [type]="'button'"
            [style]="'primary'"
            [materialStyle]="'flat'"
            (clickEvent)="onExplore()"
          >
            <span class="text-wrapper">EXPLORE</span>
          </itq-mat-button>
        </div>
      </div>
      <div class="flex flex-1 flex-col">
        <div class="p-4 bg-white border-b border-border">
          <itq-input
            [formControlName]="'query'"
            [icon]="'search'"
            [placeholder]="'Search alerts...'"
            [type]="'text'"
            (search)="onGetNotifications()"
            (clear)="onGetNotifications()"
          ></itq-input>
          <div class="flex mt-3">
            <itq-button-thumbnail
              [formControlName]="'showAll'"
              [dataSource]="buttonDataSource"
              (change)="onGetNotifications()"
            ></itq-button-thumbnail>
            @if (unreadCount() > 0) {
              <itq-mat-button
                class="mx-7"
                [type]="'button'"
                [materialStyle]="'stroked'"
                [padding]="Sizes.SM"
                (clickEvent)="onMarkAllRead()"
              >
                <div class="flex items-center">
                  <fa-icon [icon]="['far', 'check']"></fa-icon>MARK ALL AS READ
                </div>
              </itq-mat-button>
            }
          </div>
        </div>
        <itq-notifications-hub
          class="mb-7"
          [form]="form"
          [initialState]="initialState"
          [query]="form?.get('query')?.value"
        ></itq-notifications-hub>
      </div>
    </div>
  } @else {
    @if (researchAlertId) {
      <itq-notification-research-detail
        [form]="form"
        [researchAlertId]="researchAlertId"
        (showNotifications)="onShowNotifications()"
      ></itq-notification-research-detail>
    } @else {
      <itq-raised-other-matches
        [raisedAlertId]="raisedAlertId"
        (showNotifications)="onShowNotifications()"
      ></itq-raised-other-matches>
    }
  }
</form>
<ng-template #unreadTemplate> UNREAD ({{ unreadCount() || 0 }}) </ng-template>
@if (showLoader) {
  <itq-loader></itq-loader>
}
