import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Query } from '@portal/shared/models/query-model';
import { cloneDeep } from 'lodash';

import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EventBusService,
  FAwesomeModule,
  IconType,
  IconTypes,
  PillType,
  QueryFilters,
  Sizes,
  SvgComponent,
  TableColumn,
} from '@intorqa-ui/core';
import { ChartTypes } from '@portal/boards/const/widget.const';
import { ChartFactory } from '@portal/boards/factories/chart';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { TagComparison } from '@portal/boards/models/tag-comparison';
import { TimeSeries } from '@portal/boards/models/time-series';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { QueryRule } from '@portal/shared/models/query-rule';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { ChartComponent } from '../../../shared/components/chart/chart.component';
import { ChartWizardComponent } from '@portal/widget-settings/modules/widget-settings-chart/chart-wizard/components/chart-wizard/chart-wizard.component';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { Board } from '@portal/boards/models/board';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
@Component({
  selector: 'itq-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  standalone: true,
  imports: [
    SvgComponent,
    CoreModule,
    FAwesomeModule,
    MatTooltipModule,
    ChartComponent,
    CommonModule,
  ],
})
export class WidgetComponent implements OnInit {
  @Input() segment: any;
  @Input() widget: TagAnalysis | TagComparison | TimeSeries;
  @Input() action: WidgetActions;
  @Input() board: Board;
  @Input() query: string;

  @Output() delete = new EventEmitter<
    TagAnalysis | TagComparison | TimeSeries
  >();
  @Output() update = new EventEmitter<
    TagAnalysis | TagComparison | TimeSeries
  >();

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;
  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public data: IWidgetData;
  public showLoader = true;
  public chartInstance: ECharts;
  public displayTypes: Array<IDisplayType>;
  public tableColumns: Array<TableColumn> = [];
  public selectedDisplayType: IDisplayType;
  private subscriptions = new Subscription();
  private initialState: QueryFilters;

  readonly ChartFactory = ChartFactory;
  readonly PillType = PillType;
  readonly AnalysisTypes = AnalysisTypes;
  readonly WidgetActions = WidgetActions;
  readonly Sizes = Sizes;
  readonly IconTypes = IconTypes;
  readonly ChartType = ChartType;
  readonly Align = Align;

  constructor(
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly eventBusService: EventBusService,
    readonly boardService: BoardService,
  ) {}

  ngOnInit(): void {
    this.bindReloadWidgetSubscription();
    this.registerEventBusEvents();
    this.selectedDisplayType = this.widget.getSelectedType();
    this.displayTypes = this.widget?.getDisplayTypes();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: {
          widget: TagAnalysis | TagComparison | TimeSeries;
          initialState: QueryFilters;
        }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.widget.widgetId
          ) {
            this.initialState = params.initialState;
            this.getWidgetData();
          }
        },
      ),
    );
  }

  public onEdit(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartWizardComponent,
          inputs: {
            widget: cloneDeep(this.widget),
            action: WidgetActions.SETTINGS,
            initialState: this.initialState,
          },
          outputs: {
            close: () => {
              customOverlay.close();
            },
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
  public onDeleteWidget(
    widget: TagAnalysis | TagComparison | TimeSeries,
  ): void {
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [widget.widgetId],
      })
      .subscribe();
  }

  public onExportWidget(): void {
    const exportImage = (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      const element = document.createElement('a');
      element.setAttribute('href', img.src);
      element.setAttribute('download', this.widget.name + '.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };
    if (ChartTypes.ECHARTS.includes(this.widget.chartType)) {
      exportImage(
        this.chartInstance.getDataURL({
          pixelRatio: 2,
          backgroundColor: 'transparent',
        }),
      );
    } else {
      html2canvas(this.chart.instance.chartContainer.nativeElement).then(
        (canvas: any) => {
          exportImage(canvas.toDataURL('image/png'));
        },
      );
    }
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.widgetService.updateWidget(this.widget).subscribe();
  }

  public onLoadCount(row: IDataPoint): void {
    const queryModel = new Query();
    const widgetCopy = cloneDeep(this.widget);
    widgetCopy.name = `${widgetCopy.name}: ${row.category}`;
    if (this.initialState.query) {
      const rule = new QueryRule(
        DTOQueryFieldType.content,
        DTOQueryConditionOperator.contains,
        [this.initialState.query],
      );
      queryModel.addRule(rule);
    }
    const rules: Array<QueryRule> = [
      new QueryRule(
        row.tagId.search('Field Filter') > -1
          ? DTOQueryFieldType.filter
          : DTOQueryFieldType.tag,
        DTOQueryConditionOperator.in,
        [row.tagId],
      ),
    ];
    if (this.widget.type === AnalysisTypes.TAG_ANALYSIS) {
      new QueryRule(
        DTOQueryFieldType.tag,
        DTOQueryConditionOperator.in,
        (this.widget as TagAnalysis).dataSource,
      );
    }
    const navigationItem = new NavigationHistoryItem(
      `${this.widget.widgetId}_${WidgetActions.EXPLORE}`,
      cloneDeep(this.widget),
      WidgetActions.EXPLORE,
      rules,
      new QueryFilters(30, 1, this.initialState.where, undefined, undefined),
      new FormGroup({}),
      'search',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        initialState: this.initialState,
        segment: {
          data: {
            tagId: row.tagId,
            name: row.category,
            value: row.count,
          },
        },
        actions: [
          {
            action: ToolbarActions.RESET_FILTERS,
          },
          {
            action: ToolbarActions.DATE,
            expanded: this.initialState?.where ? true : false,
            data: {
              date: this.initialState?.where,
            },
          },
          {
            action: ToolbarActions.REFRESH,
          },
        ],
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(segment?: any): void {
    const widget = cloneDeep(this.widget);
    const navigationItem = new NavigationHistoryItem(
      `${this.widget.widgetId}_${WidgetActions.EXPLORE}`,
      widget,
      WidgetActions.EXPLORE,
      undefined,
      this.initialState,
      new FormGroup({}),
      'magnifying-glass',
      IconType.FONT_AWESOME,
      this.selectedDisplayType,
      segment,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        segment,
      },
      closeBtnStyle: 'basic',
      size: "'lg'",
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private getWidgetData(): void {
    this.showLoader = true;

    const queryModel = new Query();
    if (this.initialState?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [this.initialState?.query],
        ),
      );
    }
    const state = {
      where: this.initialState?.where,
    };

    this.widgetService
      .getData(
        {
          widget: this.widget,
          filters: queryModel.modelToDTO(),
        },
        state,
      )
      .then((response: IWidgetData) => {
        this.data = response;
        if (this.widget.chartType === ChartType.TABLE) {
          this.tableColumns = this.widget.getTableColumns(this.countTemplate);
        }
        this.cdr.detectChanges();
        this.widgetService.reloadChart$.next(this.widget);
        this.showLoader = false;
      });
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.delete.emit(this.widget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: TagAnalysis | TagComparison | TimeSeries;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: TagAnalysis | TagComparison | TimeSeries;
        address: string;
        type: string;
      },
    ) => {
      this.widget = WidgetFactory.createObject(msg.body) as
        | TagAnalysis
        | TagComparison
        | TimeSeries;
      this.selectedDisplayType = this.widget.getSelectedType();
      if (this.widget.chartType === ChartType.TABLE) {
        this.tableColumns = this.widget.getTableColumns(this.countTemplate);
      }
      this.initialState.resetPagination();
      this.getWidgetData();
    };
  }
}
