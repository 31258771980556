import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  CoreModule,
  FAwesomeModule,
  QueryFilters,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PriorityColor } from '@portal/notifications/enums/notification.enum';
import { AlertTypes } from '@portal/notifications/enums/alerts.enum';
import {
  GroupNotification,
  TagMatchNotification,
  ThresholdNotification,
  TransactionalNotification,
  ResearchNotification,
  Notification,
} from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NotificationsResearchComponent } from '../notifications-research/notifications-research.component';
import { NotificationsTagMatchComponent } from '../notifications-tag-match/notifications-tag-match.component';
import { NotificationsTagThresholdComponent } from '../notifications-tag-threshold/notifications-tag-threshold.component';
import { NotificationsTransactionalComponent } from '../notifications-transactional/notifications-transactional.component';

@Component({
  selector: 'itq-notifications-timeline',
  templateUrl: './notifications-timeline.component.html',
  styleUrls: ['./notifications-timeline.component.scss'],
  standalone: true,
  imports: [CoreModule, ReactiveFormsModule, CommonModule, FAwesomeModule],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsTimelineComponent {
  @Input() raisedAlertId: string;
  @Input() initialState: QueryFilters;
  @Input() dataSource: { items: Array<GroupNotification>; totalCount: number };

  @Output() drilldown = new EventEmitter<{
    segment?: ISegment;
    notification?: Notification;
  }>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  private scrollTop = 0;
  private scrollSubject = new Subject<void>();
  private subscriptions = new Subscription();

  @HostBinding('style.borderRight') borderRight: string;
  @HostBinding('class') class: string;

  readonly PriorityColor = PriorityColor;

  @ViewChildren('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainers: QueryList<ViewContainerRef>;

  @HostListener('scroll', ['$event'])
  scroll(): void {
    this.scrollSubject.next();
  }

  constructor(
    readonly componentFactoryResolver: ComponentFactoryResolver,
    readonly notificationsService: NotificationsService,
    private elementRef: ElementRef,
    private virtualScrollService: VirtualScrollService,
    readonly ngZone: NgZone,
  ) {
    this.ngZone.runOutsideAngular(() => {
      this.scrollSubject
        .pipe(debounceTime(200)) // 200ms debounce time
        .subscribe(() => this.onScroll());
    });
  }

  ngOnInit(): void {
    this.bindScrollingSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.createComponents();
      setTimeout(() => {
        this.elementRef.nativeElement.scrollTop = this.scrollTop;
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindScrollingSubscribe(): void {
    this.subscriptions =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.elementRef.nativeElement.scrollTop = 0;
      });
  }

  private onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.elementRef.nativeElement,
          this.initialState.pageSize,
          this.dataSource.totalCount,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound(this.initialState);
          }
        });
    }
  }

  public createComponents(): void {
    this.dynamicComponentContainers.changes.subscribe(
      (containers: QueryList<ViewContainerRef>) => {
        // Clear all containers
        let count = 0;
        containers.toArray().forEach((container) => container.clear());

        // Create a new dynamic component for each container
        this.dataSource?.items.forEach((item) => {
          item.notifications.forEach(
            (
              notification:
                | TagMatchNotification
                | ThresholdNotification
                | TransactionalNotification
                | ResearchNotification,
            ) => {
              if (notification.alertTypeName === AlertTypes.TAG_MATCH) {
                let tagMatchComponentFactory: ComponentFactory<NotificationsTagMatchComponent>;
                tagMatchComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTagMatchComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(tagMatchComponentFactory);
                componentRef.instance.notification =
                  notification as TagMatchNotification;
                componentRef.instance.drilldown.subscribe(
                  (params: {
                    segment?: ISegment;
                    notification?: Notification;
                  }) => {
                    this.drilldown.emit(params);
                  },
                );
              } else if (notification.alertTypeName === AlertTypes.THRESHOLD) {
                let thresholdComponentFactory: ComponentFactory<NotificationsTagThresholdComponent>;
                thresholdComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTagThresholdComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(thresholdComponentFactory);

                componentRef.instance.notification =
                  notification as ThresholdNotification;
              } else if (notification.alertTypeName === AlertTypes.SYSTEM) {
                let transactionalComponentFactory: ComponentFactory<NotificationsTransactionalComponent>;
                transactionalComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsTransactionalComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(transactionalComponentFactory);

                componentRef.instance.notification =
                  notification as TransactionalNotification;
              } else {
                let researchComponentFactory: ComponentFactory<NotificationsResearchComponent>;
                researchComponentFactory =
                  this.componentFactoryResolver.resolveComponentFactory(
                    NotificationsResearchComponent,
                  );
                let componentRef = containers
                  .toArray()
                  [count].createComponent(researchComponentFactory);

                componentRef.instance.notification =
                  notification as ResearchNotification;
              }

              count += 1;
            },
          );
        });
      },
    );
  }

  public onDataBound(params: QueryFilters): void {
    this.notificationsService.getNotifications$.next(params);
  }
}
