<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="flex h-full flex-col w-full"
>
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon
        [icon]="['far', action === WidgetActions.SETTINGS ? 'pencil' : 'plus']"
        [size]="'2x'"
      ></fa-icon>
      <h1 class="text-28 font-bold pl-4">
        {{
          action === WidgetActions.SETTINGS ? 'Edit widget' : 'Create widget'
        }}
      </h1>
      <itq-pill
        class="ml-7"
        [class]="
          'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over'
        "
        [type]="PillType.CUSTOM"
        >{{ widget.type | mapLabel }}</itq-pill
      >
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
    ></itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div
      class="w-[300px] flex-col flex px-4 border-r border-border overflow-auto pb-7"
    >
      <h2 class="flex font-bold py-7 text-20">Configure widget</h2>
      <ng-template dynamicPlaceholder></ng-template>
    </div>
    <div class="flex flex-1 items-center flex-col overflow-hidden">
      <div class="flex flex-1 flex-col w-full overflow-hidden">
        <div class="flex items-center px-4 py-3 justify-start w-full">
          <span class="font-semibold mr-2">Display:</span>
          <itq-button-thumbnail
            [dataSource]="chartTypesDataSource"
            formControlName="chartType"
            (change)="onChangeChartType($event)"
          ></itq-button-thumbnail>
        </div>
        <div
          class="flex flex-col border-t border-border bg-background p-7 flex-1 overflow-hidden"
        >
          <div class="flex w-full flex-col flex-1 overflow-hidden">
            @if (widget.hasOptions()) {
              <div class="font-semibold pb-4 text-metadata">Preview:</div>
              @if (widget?.chartType === ChartType.TABLE) {
                @if (data?.totalHits > 0) {
                  <itq-table
                    class="overflow-hidden"
                    [tableData]="data?.series[0]?.data"
                    [tableColumns]="tableColumns"
                  >
                  </itq-table>
                }
              } @else if (widget?.chartType === ChartType.TIMELINE) {
                @if (timelineData) {
                  <itq-timeline-feed
                    class="overflow-auto border border-border rounded-md"
                    [widget]="widget"
                    [data]="timelineData"
                    [initialState]="initialState"
                    [allowDrilldown]="false"
                    (scroll)="onDataBound($event)"
                  ></itq-timeline-feed>
                }
              } @else {
                <itq-chart [widget]="widget" [data]="data"></itq-chart>
              }
            } @else {
              <itq-panel-action
                class="mt-7"
                [label]="widget | selectMetricsLabel"
                [description]="
                  'You can change the display type by clicking one of the tabs above'
                "
              ></itq-panel-action>
            }
          </div>
          <div class="flex justify-start w-full items-baseline mt-4">
            <div class="flex flex-col flex-1">
              <span class="mb-1 text-13 font-medium">NAME</span>
              <itq-input
                [formControlName]="'name'"
                [placeholder]="
                  widget.type === AnalysisTypes.TIMELINE
                    ? 'Timeline name'
                    : 'Chart name...'
                "
                (ngModelChange)="this.widget.name = $event"
              ></itq-input>
              @if (
                form.controls['name']?.touched &&
                form.controls['name']?.hasError('required')
              ) {
                <mat-error> Name is required </mat-error>
              }
            </div>
            <div class="flex flex-col flex-1">
              <span class="mb-1 text-13 font-medium">DESCRIPTION</span>
              <itq-input
                class="flex-1"
                [formControlName]="'description'"
                [placeholder]="
                  widget.type === AnalysisTypes.TIMELINE
                    ? 'Timeline description'
                    : 'Chart description...'
                "
                (ngModelChange)="this.widget.description = $event"
              ></itq-input>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full px-7 py-4 border-t border-border">
        <itq-mat-button
          [materialStyle]="'flat'"
          [style]="'primary'"
          [type]="'button'"
          (clickEvent)="onPrev()"
        >
          <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
          PREV
        </itq-mat-button>
        <itq-mat-button
          [materialStyle]="'flat'"
          [style]="'primary'"
          [type]="'submit'"
        >
          SAVE
        </itq-mat-button>
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader> </itq-loader>
}

<ng-template #countTemplate let-row>
  {{ row.count }}
</ng-template>
