import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  FAwesomeModule,
  MillisToDate,
  QueryFilters,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import {
  AlertTypesIcons,
  AlertTypesLabel,
} from '@portal/notifications/enums/alerts.enum';
import {
  PriorityColor,
  PriorityLabel,
} from '@portal/notifications/enums/notification.enum';
import { ResearchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-notifications-research',
  templateUrl: './notifications-research.component.html',
  styleUrls: ['./notifications-research.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
    SvgComponent,
  ],
})
export class NotificationsResearchComponent {
  @Input() notification: ResearchNotification;
  @Input() form: FormGroup;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );
  @Input() showDocument = false;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly AlertTypesLabel = AlertTypesLabel;

  private subscription = new Subscription();
  public isTextTruncated = false;

  @ViewChild('textContainer') textContainer: ElementRef;

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    this.bindToggleDocumentSubscription();
    this.bindMarkAsReadSubscription();
    this.class = !this.notification.read ? 'unread' : '';
  }

  ngAfterViewInit(): void {
    this.checkTextOverflow();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkTextOverflow(): void {
    const element = this.textContainer.nativeElement;
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
    const maxHeight = lineHeight * 3;
    this.isTextTruncated = element.scrollHeight > maxHeight;
  }

  public onToggle(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.showDocument = !this.showDocument;
    this.notificationService.toggleNotification$.next(
      this.notification.raisedAlertId,
    );
  }

  private bindMarkAsReadSubscription(): void {
    this.subscription.add(
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      }),
    );
  }

  private bindToggleDocumentSubscription(): void {
    this.subscription.add(
      this.notificationService.toggleNotification$.subscribe(
        (response: string) => {
          if (response !== this.notification.raisedAlertId) {
            this.showDocument = false;
          }
        },
      ),
    );
  }

  public onToggleRead(): void {
    this.subscription.add(
      this.notificationService
        .toggleReadState(
          this.notification.raisedAlertId,
          !this.notification.read,
        )
        .subscribe((response: ResearchNotification) => {
          this.notification = response;
          this.class = !this.notification.read ? 'unread' : '';
          this.notificationService
            .getUnreadNotificationsCount(
              this.initialState,
              this.form?.get('priority')?.value,
              this.form?.get('alertType')?.value,
            )
            .subscribe();
        }),
    );
  }
}
