import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreModule, Sizes } from '@intorqa-ui/core';
import {
  INotification,
  IResearchNotification,
} from '@portal/notifications/interfaces/notification.interface';
import { ResearchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { NotificationsResearchComponent } from '../notifications-research/notifications-research.component';
@Component({
  selector: 'itq-notification-research-detail',
  templateUrl: './notification-research-detail.component.html',
  styleUrls: ['./notification-research-detail.component.scss'],
  standalone: true,
  imports: [NotificationsResearchComponent, CoreModule],
})
export class NotificationResearchDetailComponent implements OnInit {
  @Input() researchAlertId: string;
  @Input() form: FormGroup;

  @Output() showNotifications = new EventEmitter<void>();

  readonly Sizes = Sizes;

  public notification: ResearchNotification;

  constructor(
    readonly notificationService: NotificationsService,
    readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.notificationService
      .getNotificationById(this.researchAlertId)
      .subscribe((response: INotification) => {
        const notification = response as IResearchNotification;
        this.notification = new ResearchNotification(
          notification.raisedAlertId,
          notification.createdDate,
          notification.alertTypeName,
          notification.priority,
          notification.message,
          notification.read,
          notification.headline,
          notification.rawMessage,
          notification.ecosystemId,
        );
      });
  }

  public onLoadNotifications(): void {
    this.showNotifications.emit();
  }
}
