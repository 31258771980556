import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BoardNavigationComponent } from '../board-navigation/board-navigation.component';
import { BoardSettingsComponent } from '../board-settings/board-settings.component';
import { BoardComponent } from '../board/board.component';

@Component({
  selector: 'itq-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.scss'],
  standalone: true,
  imports: [
    BoardNavigationComponent,
    BoardSettingsComponent,
    BoardComponent,
    RouterModule,
  ],
})
export class BoardsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
