<itq-mat-button
  class="w-[350px] m-4"
  [size]="Sizes.BLOCK"
  [type]="'button'"
  [materialStyle]="'stroked'"
  (clickEvent)="onLoadNotifications()"
>
  <span class="text-wrapper">SEARCH NOTIFICATIONS</span>
</itq-mat-button>
<itq-virtual-table
  class="flex min-w-[500px] p-4 flex-1 border-l border-border"
  [initialState]="initialState"
  [tableColumns]="tableColumns"
  [theme]="'tile'"
  [headerVisible]="false"
  [dataSource]="matchesDataSource?.items"
  [totalCount]="matchesDataSource?.totalHits"
  (dataBound)="onDataBound($event)"
></itq-virtual-table>
<ng-template #template let-data>
  <itq-document-item-tile-v2
    [document]="data"
    [allowDrilldown]="false"
  ></itq-document-item-tile-v2>
</ng-template>
