<div class="flex relative flex-col flex-1 overflow-hidden">
  <itq-input
    class="p-1"
    [(ngModel)]="query"
    [icon]="'search'"
    [placeholder]="'Search...'"
    (clear)="onSearch()"
    (search)="onSearch()"
  ></itq-input>
  <ul #list class="overflow-auto h-full" (scroll)="onScroll()">
    @for (item of dataSource?.items; track item[dataFields.value]) {
      <li class="items-center h-[48px] border-t border-border">
        <itq-mat-button
          class="flex h-full"
          [align]="Align.START"
          [style]="(item | isSelected: selectedItems) ? 'primary' : undefined"
          [disabled]="item.disabled"
          [matTooltip]="item.tooltip"
          [materialStyle]="'basic'"
          [type]="'button'"
          [size]="Sizes.BLOCK"
          (clickEvent)="onClick(item)"
        >
          <div class="flex justify-between items-center font-medium w-full">
            <span class="whitespace-nowrap overflow-hidden text-ellipsis">
              {{ item[dataFields.name] }}
            </span>
            @if (item | isSelected: selectedItems) {
              <fa-icon [icon]="['far', 'circle-check']" [size]="'lg'"></fa-icon>
            }
          </div>
        </itq-mat-button>
      </li>
    }
  </ul>
</div>

@if (dataSource && dataSource?.items?.length === 0) {
  <itq-panel-info
    [label]="'No items found!'"
    [message]="'Please update your query...'"
    [icon]="'exclamation-circle'"
  ></itq-panel-info>
}
