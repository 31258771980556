import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicComponentConfig } from './modal-container.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalContainerService {
  public navigate$ = new Subject<DynamicComponentConfig>();
  public loader$ = new Subject<boolean>();

  constructor() {}
}
