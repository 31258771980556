import { Pipe, PipeTransform } from '@angular/core';
import { ITimelineTagMetric } from './timeline-metrics-list.interface';

@Pipe({
  name: 'isSelected',
  standalone: true,
})
export class IsSelectedPipe implements PipeTransform {
  transform(
    item: ITimelineTagMetric,
    selectedItems: Array<ITimelineTagMetric>,
  ): boolean {
    return selectedItems.some((selectedItem) => selectedItem.id === item.id);
  }
}
