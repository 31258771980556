<div class="flex flex-col" [formGroup]="form">
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA SOURCE</span>
    <mat-radio-group
      formControlName="typeDataSource"
      (change)="onChangeDataSourceType($event)"
    >
      <mat-radio-button [value]="DataSourceType.ALL">
        Compare tag volumes across all posts
      </mat-radio-button>
      <mat-radio-button [value]="DataSourceType.SET">
        Filter to analyse within a specific tag
      </mat-radio-button>
    </mat-radio-group>
    @if (this.form?.controls?.typeDataSource?.value === DataSourceType.SET) {
      <itq-specific-tag-datasource
        [form]="form"
        [widget]="timeSeriesWidget"
        [dates]="dates"
        (changeDataSource)="onChangeDataSources($event)"
        (changeDataType)="onChangeDataType($event)"
        (changeDataValue)="onChangeDataValues($event)"
      ></itq-specific-tag-datasource>
    } @else {
      <itq-all-tags-datasource
        [dates]="dates"
        [form]="form"
        [widget]="timeSeriesWidget"
        (changeDataValue)="onChangeDataValues($event)"
      ></itq-all-tags-datasource>
    }

    <div class="flex mb-2 flex-col">
      <span class="mb-1 text-13 font-medium">INTERVAL</span>
      <itq-dropdown
        formControlName="interval"
        [showHeader]="false"
        [clearSelection]="false"
        [dataSource]="intervalDataSource"
        [dataFields]="{ name: 'name', value: 'value' }"
        (changeValue)="onChangeInterval()"
      ></itq-dropdown>
    </div>
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [form]="form"
      [dates]="this.dates"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>
