<div class="flex flex-col" [formGroup]="form">
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">TAG</span>
    <itq-dropdown
      formControlName="dataSource"
      [clearSelection]="false"
      [httpBinding]="true"
      [initialState]="initialState"
      [dataSource]="tagsDataSource"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Please select an item...'"
      (changeValue)="onChangeDataSources()"
      (dataBound)="onGetTags($event)"
    ></itq-dropdown>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data source is required </mat-error>
    }
  </div>
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA SLICER</span>
    <itq-dropdown
      [clearSelection]="false"
      formControlName="dataPoints"
      [dataSource]="dataPointsDataSource"
      (changeValue)="onChangeDatePoints()"
      [dataFields]="{ name: 'name', value: 'name' }"
    ></itq-dropdown>
    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
    }
  </div>
  <div class="flex flex-col mb-2">
    <span class="mb-1 text-13 font-medium">TOP</span>
    <itq-dropdown
      formControlName="top"
      [clearSelection]="false"
      [dataSource]="topDataSource"
      (changeValue)="onChangeTop()"
      [dataFields]="{ name: 'name', value: 'value' }"
    ></itq-dropdown>
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [form]="form"
      [dates]="this.dates"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>
