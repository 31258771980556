import { cloneDeep } from 'lodash';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRange,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  FormatDatePipe,
  IconType,
  IError,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  TableColumn,
  VirtualScrollService,
} from '@intorqa-ui/core';

import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { Tag } from '@portal/shared/models/tag';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { DeleteTagDependenciesComponent } from './delete-tag-dependencies/delete-tag-dependencies.component';
import { TagDependenciesComponent } from './tag-dependencies/tag-dependencies.component';
import moment from 'moment';
import { TagManagerFilters } from '@portal/shared/const/tag.const';
import { FilterType } from '@portal/shared/enums/tag.enum';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { WidgetService } from '@portal/boards/services/widget.service';

@Component({
  selector: 'itq-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    FAwesomeModule,
    FormatDatePipe,
    ReactiveFormsModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
  ],
})
export class TagsComponent implements OnInit {
  public initialState = new QueryFilters(50, 1, undefined, undefined, {
    direction: 'desc',
    active: 'updatedDate',
  });
  public form: FormGroup;
  private subscriptions: Subscription = new Subscription();

  public dataSource: {
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  };
  public tableColumns: TableColumn[];
  public tagManagerFilters = TagManagerFilters;
  public type = FilterType.ALL;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;

  @ViewChild('alertsTemplate') alertsTemplate: TemplateRef<unknown>;
  @ViewChild('sharedTickTemplate') sharedTickTemplate: TemplateRef<unknown>;
  @ViewChild('intorqaTickTemplate') intorqaTickTemplate: TemplateRef<unknown>;
  @ViewChild('editTemplate') editTemplate: TemplateRef<unknown>;
  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;

  constructor(
    readonly sharedService: SharedService,
    private virtualScrollService: VirtualScrollService,
    private tagService: TagService,
    readonly userService: UserService,
    private snackBar: MatSnackBar,
    public keycloakService: KeycloakService,
    private customOverlayService: CustomOverlayService,
    readonly widgetService: WidgetService,
  ) {}

  ngOnInit() {
    this.bindReloadTagsSubscription();
    this.bindChangeEcosystemSubscription();
    this.initForm();
    this.onDataBound();
    this.setFilters();
  }

  ngAfterViewInit(): void {
    this.initColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindReloadTagsSubscription(): void {
    this.subscriptions.add(
      this.tagService.reloadTags$.subscribe(() => {
        this.initialState.resetPagination().then(() => {
          this.virtualScrollService.dataBoundObservable.next();
          this.sharedService.loader$.next(true);
          this.onDataBound();
        });
      }),
    );
  }

  private setFilters(): void {
    if (!this.keycloakService.isUserInRole('saas-alerts')) {
      this.tagManagerFilters = this.tagManagerFilters.filter(
        (item: { id: string; value: string }) => item.id !== FilterType.ALERTS,
      );
    }

    if (
      !(
        this.keycloakService.isUserInRole('super-admin') ||
        this.keycloakService.isUserInRole('admin')
      )
    ) {
      this.tagManagerFilters = this.tagManagerFilters.filter(
        (item: { id: string; value: string }) =>
          item.id !== FilterType.COMMUNITIES,
      );
    }
  }

  private initForm(): void {
    this.form = new FormGroup({
      searchTerm: new FormControl(''),
    });
  }

  public onChangeType(): void {
    this.sharedService.loader$.next(true);
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
      this.sharedService.loader$.next(true);
      this.onDataBound();
    });
  }

  public onDataBound(): void {
    this.sharedService.loader$.next(true);
    this.tagService
      .getTags(
        this.initialState,
        this.form,
        this.userService.userPreferences.defaultEcosystemId,
        this.type,
      )
      .subscribe(
        (response: {
          items: Array<Tag>;
          page: number;
          pageSize: number;
          totalCount: number;
        }) => {
          if (this.initialState.page === 1) {
            this.dataSource = response;
          } else {
            this.dataSource.items = [
              ...this.dataSource.items,
              ...response.items,
            ];
          }
          this.sharedService.loader$.next(false);
        },
      );
  }

  initColumns(): void {
    this.tableColumns = [
      {
        name: 'Name',
        dataKey: 'name',
        isSortable: true,
        width: 'fit',
      },
      {
        name: 'Updated',
        dataKey: 'updatedDate',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Created',
        dataKey: 'createdDate',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Shared',
        dataKey: 'sharedTag',
        position: 'center',
        customRender: true,
        template: this.sharedTickTemplate,
        width: '120px',
      },
      {
        name: 'System tag',
        dataKey: 'categoryId',
        position: 'center',
        customRender: true,
        template: this.intorqaTickTemplate,
        width: '140px',
      },
      {
        name: undefined,
        dataKey: 'settings',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '47px',
      },
      {
        name: undefined,
        dataKey: 'edit',
        position: 'center',
        customRender: true,
        template: this.editTemplate,
        width: '77px',
      },
    ];

    if (this.keycloakService.isUserInRole('saas-alerts')) {
      this.tableColumns.splice(5, 0, {
        name: 'Has Alerts?',
        dataKey: 'alertTypeId',
        position: 'center',
        customRender: true,
        template: this.alertsTemplate,
        width: '160px',
      });
    }
  }

  public onCreate(): void {
    const widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      undefined,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_tags`,
      widget,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(
        30,
        1,
        {
          label: DateQueryType.LastMonth,
        },
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
        actions: [
          {
            action: ToolbarActions.RESET_FILTERS,
          },
          {
            action: ToolbarActions.DATE,
            expanded: false,
          },
          {
            action: ToolbarActions.REFRESH,
          },
        ],
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }
    });
  }

  onEdit(tag: Tag): void {
    const timeline = this.widgetService.mergeTagIntoTimeline(tag);
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.SETTINGS}_${timeline.dataSource[0]}`,
      cloneDeep(timeline),
      WidgetActions.SETTINGS,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'pencil',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
        actions: [
          {
            action: ToolbarActions.RESET_FILTERS,
          },
          {
            action: ToolbarActions.DATE,
            expanded: false,
          },
          {
            action: ToolbarActions.REFRESH,
          },
        ],
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }
    });
  }

  onDelete(tag: Tag): void {
    this.customOverlayService.openCustom(
      {
        title: 'Delete tag?',
        message: `Are you sure you want to permanently delete the '<strong>${tag?.name}</strong>' tag?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.tagService
            .delete(tag.tagId)
            .then(() => {
              this.sharedService.loader$.next(true);
              this.onDataBound();
              this.snackBar.open('Your tag has been deleted!', 'Close', {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              });
            })
            .catch((errorResponse: IError) => {
              if (errorResponse) {
                this.customOverlayService.openCustom(
                  {
                    title: 'Oooops!',
                    data: errorResponse.description,
                    message: errorResponse.description,
                    component: DeleteTagDependenciesComponent,
                    icon: ['far', 'exclamation-circle'],
                    size: '4x',
                    dialog: {
                      type: DialogTypes.ALERT,
                    },
                  },
                  DialogComponent,
                );
              }
            });
        }
      },
    );
  }

  public onClone(tag: Tag): void {
    const timeline = this.widgetService.mergeTagIntoTimeline(tag);
    timeline.name = `${timeline.name} (clone)`;
    const navigationItem = new NavigationHistoryItem(
      `tag_manager_${WidgetActions.CLONE}`,
      timeline,
      WidgetActions.CLONE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'clone',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(() => {
      this.onSearch();
    });
  }

  public onViewHierarchy(tag: Tag): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        tagId: tag.tagId,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'min-w-[900px]',
      component: TagDependenciesComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(() => {
      this.onSearch();
    });
  }

  public onSearch(): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
      this.sharedService.loader$.next(true);
      this.onDataBound();
    });
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.sharedService.changeEcosystem$.subscribe(() => {
        this.onSearch();
      }),
    );
  }
}
