import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CUSTOM_OVERLAY_DATA,
  CoreModule,
  CustomOverlayConfig,
  DynamicPlaceholderDirective,
  FAwesomeModule,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { DynamicComponentConfig } from './modal-container.interface';
import { ModalContainerService } from './modal-container.service';

@Component({
  selector: 'itq-modal-container',
  templateUrl: './modal-container.component.html',
  standalone: true,
  imports: [CoreModule, ReactiveFormsModule, FAwesomeModule],
})
export class ModalContainerComponent implements OnInit {
  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  private subscriptions = new Subscription();

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalContainerService: ModalContainerService,
  ) {}

  ngOnInit() {
    this.bindNavigationSubscription();
    this.loadComponent(this.config.data.componentConfig);
  }

  private bindNavigationSubscription(): void {
    this.subscriptions.add(
      this.modalContainerService.navigate$.subscribe(
        (componentConfig: DynamicComponentConfig) => {
          this.loadComponent(componentConfig);
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private loadComponent(config: DynamicComponentConfig): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(config.component);

    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    // Set inputs
    if (config.inputs) {
      for (const key of Object.keys(config.inputs)) {
        componentRef.instance[key] = config.inputs[key];
      }
    }

    // Subscribe to outputs
    if (config.outputs) {
      for (const key of Object.keys(config.outputs)) {
        if (componentRef.instance[key] instanceof EventEmitter) {
          componentRef.instance[key].subscribe(config.outputs[key]);
        }
      }
    }
  }
}
