<div class="flex flex-col" [formGroup]="form">
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">TAG</span>

    <itq-dropdown
      formControlName="dataSource"
      [httpBinding]="true"
      [clearSelection]="false"
      [dataSource]="tagsDataSource"
      [initialState]="dataSourcesInitialState"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Please select an item...'"
      (dataBound)="onDataBoundDataSources($event)"
      (changeValue)="onChangeDataSources()"
    ></itq-dropdown>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data source is required </mat-error>
    } @else {
      <span class="text-13 text-metadata pt-1">Select your tags to plot</span>
    }
  </div>

  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA SLICER</span>
    <itq-dropdown
      formControlName="category"
      [clearSelection]="false"
      [dataSource]="categoriesDataSource"
      [dataFields]="{ name: 'name', value: 'name' }"
      (changeValue)="onChangeDataType()"
    ></itq-dropdown>

    @if (
      form.controls['category']?.touched &&
      form.controls['category']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data slice is required </mat-error>
    }
  </div>
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA POINTS</span>
    <itq-multiple-dropdown
      formControlName="dataPoints"
      [initialState]="dataPointsInitialState"
      [dataFields]="{ name: 'name', value: 'id' }"
      [dataSource]="dataPointsDataSource"
      [httpBinding]="true"
      (dataBound)="onDataBoundDataValues($event)"
      (changeValue)="onChangeDataValues()"
    >
    </itq-multiple-dropdown>

    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data points is required </mat-error>
    }
  </div>
</div>
