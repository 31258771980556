<div class="flex justify-between w-full">
  <div class="flex">
    <div class="flex items-center">
      <div
        class="rounded-2xl p-2 shadow-none font-semibold h-6 flex items-center"
        [ngStyle]="{
          backgroundColor: '#98b5cc'
        }"
      >
        <fa-icon [icon]="['far', 'bell']"></fa-icon>
        <span class="pl-2 text-xs">{{
          AlertTypesLabel[notification.alertTypeName]
        }}</span>
      </div>
    </div>
    <div class="flex flex-col px-4 text-xs">
      <div class="font-semibold text-sm">{{ notification.headline }}</div>
      @if (notification.message) {
        <div class="flex flex-col text-metadata">
          <span>
            {{ notification.message }}
          </span>
        </div>
      }
    </div>
  </div>
  <div class="flex">
    @if (
      notification?.triggerMetadata?.action !==
        TransactionNotificationType.TAG_DELETED &&
      notification?.triggerMetadata?.action !==
        TransactionNotificationType.PROFILE_DELETED
    ) {
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.SM"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        [matTooltip]="
          TransactionNotificationTypeText[notification?.triggerMetadata?.action]
        "
        (clickEvent)="onLoadAction()"
      >
        <fa-icon
          [icon]="[
            'far',
            notification?.triggerMetadata?.action | getTransactionalAction
          ]"
        ></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.SM"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
        (clickEvent)="onToggleRead()"
      >
        <fa-icon
          [icon]="['far', notification.read ? 'check' : 'eye']"
        ></fa-icon>
      </itq-mat-button>
    }
  </div>
</div>
@if (notification?.triggerMetadata?.addition) {
  <div
    class="text-xs flex pt-4"
    [innerHTML]="notification?.triggerMetadata?.addition"
  ></div>
}
