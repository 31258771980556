import { NgModule } from '@angular/core';
import {
  AngularModule,
  CastFormGroupPipe,
  CoreModule,
  FAwesomeModule,
  FilterSelectionsPipe,
  HeadLineSimpleComponent,
  MaterialModule,
  SvgComponent,
} from '@intorqa-ui/core';
import { QueryBuilderComponent } from './query-builder/query-builder.component';
import { TimelineExploreComponent } from './timeline-explore/timeline-explore.component';
import { TimelineQueryComponent } from './timeline-query/timeline-query.component';
import { TimelineTypeAllComponent } from './timeline-type-all/timeline-type-all.component';
import { TimelineTypeListComponent } from './timeline-type-list/timeline-type-list.component';
import { TimelineWizardDetailsComponent } from './timeline-wizard/components/timeline-wizard-details/timeline-wizard-details.component';
import { TimelineWizardSettingsComponent } from './timeline-wizard/components/timeline-wizard-settings/timeline-wizard-settings.component';
import { TimelineWizardComponent } from './timeline-wizard/timeline-wizard.component';
import { WidgetSettingsTimelineComponent } from './widget-settings-timeline.component';

import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../../shared/shared.module';
import { CategoryListComponent } from './category-list/category-list.component';
import { FilterBySectionPipe } from './category-list/category-list.pipe';
import { ListSearchComponent } from './list-search/list-search.component';
import {
  FilterByCategoryPipe,
  GetExcludedSelectionsPipe,
  GetIncludedSelectionsPipe,
} from './list-search/list-search.pipe';
import { TagsBooleanSelectionComponent } from './tags-boolean-selection/tags-boolean-selection.component';
import { TimelineDrilldownComponent } from './timeline-drilldown/timeline-drilldown.component';
import { TimelineSelectionsComponent } from './timeline-selections/timeline-selections.component';
import {
  FilterSelectionsByCategoryPipe,
  FilterSelectionsColumnsPipe,
  GetSelectionsByCategoryPipe,
  GetSelectionsSectionsPipe,
  GetSelectionsSectionTagsPipe,
} from './timeline-selections/timeline-selections.pipe';
import { FilterActorCategoriesPipe } from './timeline-type-list/timeline-type-list.pipe';
import { FilterBoardsPipe } from './timeline-wizard/components/timeline-wizard-settings/timeline-wizard-settings.pipe';
import { FilterCategoriesPipe } from './timeline-wizard/timeline-wizard.pipe';
import { TagAlertsComponent } from './timeline-wizard/components/tag-alerts/tag-alerts.component';
import { NgxAngularQueryBuilderModule } from 'ngx-angular-query-builder';

@NgModule({
  imports: [
    HeadLineSimpleComponent,
    FAwesomeModule,
    MaterialModule,
    AngularModule,
    CoreModule,
    SharedModule,
    NgxAngularQueryBuilderModule,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'formats/font',
          whitelist: [
            'mirza',
            'roboto',
            'aref',
            'serif',
            'sansserif',
            'monospace',
          ],
        },
      ],
    }),
    TagAlertsComponent,
    CastFormGroupPipe,
    SvgComponent,
  ],
  declarations: [
    WidgetSettingsTimelineComponent,
    TimelineExploreComponent,
    QueryBuilderComponent,
    TimelineQueryComponent,
    TimelineTypeListComponent,
    TimelineTypeAllComponent,
    ListSearchComponent,
    FilterSelectionsPipe,
    GetSelectionsSectionsPipe,
    GetSelectionsSectionTagsPipe,
    TimelineWizardComponent,
    TimelineWizardDetailsComponent,
    TimelineWizardSettingsComponent,
    FilterCategoriesPipe,
    CategoryListComponent,
    FilterActorCategoriesPipe,
    TimelineSelectionsComponent,
    TagsBooleanSelectionComponent,
    TimelineDrilldownComponent,
    FilterBySectionPipe,
    FilterSelectionsColumnsPipe,
    FilterBoardsPipe,
    FilterByCategoryPipe,
    FilterSelectionsByCategoryPipe,
    GetSelectionsByCategoryPipe,
    GetIncludedSelectionsPipe,
    GetExcludedSelectionsPipe,
  ],
  exports: [WidgetSettingsTimelineComponent],
})
export class WidgetSettingsTimelineModule {}
