<itq-container>
  @if (segment) {
    <itq-document-item-detail
      [viewParent]="true"
      [allowDrilldown]="false"
      [segment]="segment"
      [action]="WidgetActions.EXPLORE"
    ></itq-document-item-detail>
  }
</itq-container>
