<ng-container [formGroup]="form">
  <div class="widget_settings__toolbar" *ngIf="!showLinkTags">
    <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
      this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE
        ? (timelineData?.count | number) || 0
        : (widgetData?.totalHits | number) || 0
    }}</itq-pill>
    <div class="content__switch">
      <itq-mat-button
        class="mr-2"
        [type]="'button'"
        [style]="'primary'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="text-wrapper">View content</span>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onToggleSettings()"
      >
        <fa-icon class="mr-2" [icon]="['far', 'cog']"></fa-icon>
        <span class="text-wrapper">Settings</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__container">
      <itq-toolbar
        [actions]="toolbarActions"
        [viewMode]="viewMode"
        [showTooltip]="true"
        [initialState]="initialState"
        [widget]="widget"
        [displayTypes]="displayTypes"
        [selectedDisplayType]="navigationItem.selectedDisplayType"
        [count]="timelineData?.count || widgetData?.totalHits"
        (search)="onDataBound(this.queryModel, $event)"
        (toggleView)="onToggleView($event)"
        (export)="onExportFoamtree()"
        (refresh)="onDataBound(this.queryModel, this.initialState)"
        (changeDisplayType)="onChangeDisplayType($event)"
      ></itq-toolbar>
    </div>
  </div>
  <ng-container *ngIf="!showLinkTags">
    <div
      [ngClass]="{
        widget__container: true,
        ripl__mode: viewMode === ViewMode.RIPL
      }"
    >
      <ng-container *ngIf="!widget">
        <itq-panel-action
          [label]="'No linked tags!'"
          [description]="
            'Please link some tags by clicking in the plus icon above.'
          "
        ></itq-panel-action>
      </ng-container>
      <ng-container
        *ngIf="this.queryModel.hasRules() || navigationItem?.rules?.length > 0"
      >
        <itq-search-results
          [query]="queryModel"
          [initialState]="initialState"
          [foamtreeData]="foamtreeData"
          [profile]="profile"
          [navigationItem]="navigationItem"
          [widgetData]="widgetData"
          [timelineData]="timelineData"
          [tagAnalysisWidget]="tagAnalysisWidget"
          [viewMode]="viewMode"
          [segment]="articleDetail"
          [form]="form"
          [widget]="widget"
          (drilldownFoamtree)="onDrilldownFoamtree($event)"
        ></itq-search-results>
      </ng-container>
      <itq-document-item-detail
        *ngIf="articleDetail"
        [viewParent]="true"
        [segment]="articleDetail"
        [profile]="profile"
        [form]="form"
        [navigationItem]="navigationItem"
        [action]="navigationItem?.action"
        (drilldown)="onDrilldown($event)"
      ></itq-document-item-detail>
    </div>
  </ng-container>
  <ng-container *ngIf="showLinkTags">
    <div class="content__container">
      <itq-profiles-links
        [profile]="profile"
        [action]="action"
        [navigationItem]="navigationItem"
        [form]="form"
      ></itq-profiles-links>
    </div>
  </ng-container>
</ng-container>
