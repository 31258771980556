import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Align,
  CoreModule,
  FAwesomeModule,
  IconComponent,
  IconType,
  QueryFilters,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ITimelineTagMetric } from './timeline-metrics-list.interface';
import { IsSelectedPipe } from './timeline-metrics-list.pipe';

@Component({
  selector: 'itq-timeline-metrics-list',
  templateUrl: './timeline-metrics-list.component.html',
  styleUrls: ['./timeline-metrics-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimelineMetricsListComponent,
    },
  ],
  standalone: true,
  imports: [
    CoreModule,
    MatListModule,
    FormsModule,
    IconComponent,
    CommonModule,
    MatButtonModule,
    IsSelectedPipe,
    MatTooltipModule,
    FAwesomeModule,
  ],
})
export class TimelineMetricsListComponent implements OnInit {
  private scrollTop = 0;
  public query: string;
  private touched = false;
  private disabled: boolean;
  public selectedItems: Array<ITimelineTagMetric>;
  private currentSelection: ITimelineTagMetric;

  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly Align = Align;

  @Input() dataSource: {
    items: Array<ITimelineTagMetric>;
    totalCount: number;
  };
  @Input() totalCount: number;
  @Input() template: TemplateRef<unknown>;
  @Input() dataFields: { name: string; value: string };
  @Input() initialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    undefined,
  );

  @Output() search = new EventEmitter<void>();
  @Output() changeValue = new EventEmitter<any>();

  @ViewChild('list') list: ElementRef;

  constructor(private virtualScrollService: VirtualScrollService) {}

  onChange = (items: Array<ITimelineTagMetric>) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ITimelineTagMetric>): void {
    this.selectedItems = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  public onSearch(): void {
    this.initialState.query = this.query;
    this.initialState.resetPagination();
    this.scrollTop = 0;
    this.list?.nativeElement?.scrollTo(0, 0);
    this.onDataBound();
  }

  public onDataBound(): void {
    this.search.emit();
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.list.nativeElement,
          this.initialState.pageSize,
          this.dataSource.totalCount,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }

  public onClick(item: ITimelineTagMetric): void {
    this.selectedItems = this.selectedItems.filter(
      (selectedItem: ITimelineTagMetric) => {
        return selectedItem.id !== this.currentSelection?.id;
      },
    );
    this.selectedItems = [...this.selectedItems, item];
    this.currentSelection = item;
    this.onChange(this.selectedItems);
    this.changeValue.emit(item);
  }
}
