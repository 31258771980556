import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  FAwesomeModule,
  IconType,
  MillisToDate,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';
import {
  AlertTypesIcons,
  AlertTypesLabel,
  TransactionNotificationType,
  TransactionNotificationTypeText,
} from '@portal/notifications/enums/alerts.enum';
import {
  PriorityColor,
  PriorityLabel,
} from '@portal/notifications/enums/notification.enum';
import { TransactionalNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileTypeIcon } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileService } from '@portal/profiles/services/profiles.service';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import { ChartType, WidgetActions } from '@portal/shared/enums/widget.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { QueryRule } from '@portal/shared/models/query-rule';
import { TagService } from '@portal/shared/pipes/tag.service';
import { SharedModule } from '@portal/shared/shared.module';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { GetActionPipe } from './notifications-transactional.pipe';
import { WidgetService } from '@portal/boards/services/widget.service';
import { Tag } from '@portal/shared/models/tag';

@Component({
  selector: 'itq-notifications-transactional',
  templateUrl: './notifications-transactional.component.html',
  styleUrls: ['./notifications-transactional.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
    GetActionPipe,
  ],
})
export class NotificationsTransactionalComponent {
  @Input() notification: TransactionalNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly TransactionNotificationTypeText = TransactionNotificationTypeText;
  readonly TransactionNotificationType = TransactionNotificationType;
  readonly AlertTypesLabel = AlertTypesLabel;

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly profileService: ProfileService,
    readonly router: Router,
    readonly widgetService: WidgetService,
  ) {}

  ngOnInit(): void {
    this.class = !this.notification.read ? 'unread' : '';
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TransactionalNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      });
  }

  public onLoadAction(): void {
    const { triggerMetadata } = this.notification;

    switch (triggerMetadata.action) {
      case TransactionNotificationType.TAG_CREATED:
        this.handleCreateTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_EDITED:
        this.handleEditTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_DELETED:
        this.handleDeleteTagAction();
        break;
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
        this.handleLoadProfileAction(triggerMetadata);
        break;
      case TransactionNotificationType.PROFILE_DELETED:
        this.handleDeleteProfileAction();
        break;
      default:
        break;
    }
  }

  private handleDeleteTagAction(): void {
    this.router.navigateByUrl('/tags');
    this.notificationService.hideNotifications$.next();
  }

  private handleDeleteProfileAction(): void {
    this.router.navigateByUrl('/profiles');
    this.notificationService.hideNotifications$.next();
  }

  private handleEditTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe((tag: Tag) => {
      const timeline = this.widgetService.mergeTagIntoTimeline(tag);
      const navigationItem = new NavigationHistoryItem(
        `${WidgetActions.SETTINGS}_${timeline.dataSource[0]}`,
        timeline,
        WidgetActions.SETTINGS,
        undefined,
        new QueryFilters(30, 1, undefined, undefined, undefined),
        new FormGroup({}),
        'pencil',
        IconType.FONT_AWESOME,
        undefined,
        undefined,
        undefined,
      );

      const actions = [
        { action: ToolbarActions.RESET_FILTERS },
        { action: ToolbarActions.DATE, expanded: false },
        { action: ToolbarActions.REFRESH },
      ];

      this.openCustomOverlay({ navigationItem, actions });
    });
  }

  private handleCreateTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe((tag: Tag) => {
      const timeline = this.widgetService.mergeTagIntoTimeline(tag);
      const navigationItem = new NavigationHistoryItem(
        `${timeline.widgetId}_${WidgetActions.EXPLORE}`,
        timeline,
        WidgetActions.EXPLORE,
        [
          new QueryRule(
            DTOQueryFieldType.tag,
            DTOQueryConditionOperator.in,
            timeline.dataSource,
          ),
        ],
        new QueryFilters(30, 1, undefined, undefined, undefined),
        new FormGroup({}),
        'magnifying-glass',
        IconType.FONT_AWESOME,
        TIMELINE_CHART_TYPES.find(
          (item: IDisplayType) => item.type === ChartType.TIMELINE,
        ),
        undefined,
        undefined,
      );

      this.openCustomOverlay({ navigationItem });
    });
  }

  private handleLoadProfileAction(triggerMetadata: {
    [key: string]: any;
  }): void {
    const { id } = triggerMetadata;
    this.profileService.getProfileById(id).subscribe((profile: Profile) => {
      let navigationItem: any;
      const queryFilters = new QueryFilters(30, 1, undefined, undefined, {
        direction: 'desc',
        active: 'updatedDate',
      });
      const profileTypeIcon = ProfileTypeIcon[profile?.profileTypeName];
      const timelineChart = {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      };

      if (
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_CREATED ||
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_DELETED ||
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_UPDATED
      ) {
        navigationItem = new ProfilesNavigationItem(
          `${WidgetActions.EXPLORE}_${profile.profileId}`,
          profile,
          WidgetActions.EXPLORE,
          undefined,
          queryFilters,
          new FormGroup({}),
          profileTypeIcon,
          IconType.FONT_AWESOME,
          timelineChart,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          2,
        );
      } else {
        navigationItem = new NavigationHistoryItem(
          `${WidgetActions.EXPLORE}_${profile.profileId}`,
          profile,
          WidgetActions.EXPLORE,
          undefined,
          queryFilters,
          new FormGroup({}),
          profileTypeIcon,
          IconType.FONT_AWESOME,
          timelineChart,
          undefined,
          undefined,
        );
      }

      this.openCustomOverlay({ navigationItem });
    });
  }

  private openCustomOverlay(data: any): void {
    this.customOverlayService.open({
      data,
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
