import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  IPresetQuery,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { TimelineMetricsListComponent } from '../timeline-metrics-list/timeline-metrics-list.component';
import { ITimelineTagMetric } from '../timeline-metrics-list/timeline-metrics-list.interface';

@Component({
  selector: 'itq-timeline-metrics',
  templateUrl: './timeline-metrics.component.html',
  styleUrls: ['./timeline-metrics.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TimelineMetricsListComponent,
  ],
})
export class TimelineMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.timelineWidget = widget as Timeline;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public tagsDataSource: {
    items: Array<ITimelineTagMetric>;
    totalCount: number;
  };
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    undefined,
  );
  public timelineWidget: Timeline;
  private tagIds: Array<string> = [];

  constructor(
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    this.getWidgetsOnBoard();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
  }

  public getWidgetsOnBoard(): void {
    this.widgetSettingsService.loader$.next(true);
    this.boardService
      .getWidgets(this.boardService.board)
      .then((widgets: Array<Widget>) => {
        this.tagIds = widgets
          .filter((item: Widget) => item.type === AnalysisTypes.TIMELINE)
          .map((item: Widget) => (item as Timeline).dataSource[0]);
        this.onGetTags();
      });
  }

  public onGetTags(): void {
    this.widgetSettingsService.loader$.next(true);
    this.categoryService
      .getTags(
        this.initialState.query,
        this.initialState,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState.page > 1
          ? this.tagsDataSource?.items[this.tagsDataSource?.items?.length - 1]
              .name
          : undefined,
      )
      .then((response: { items: Array<ICustomTag>; totalCount: number }) => {
        let tags: Array<ITimelineTagMetric> = [];
        const dataSource = response?.items?.map((item: ICustomTag) => {
          if (!this.tagIds.includes(item.id) && item.count > 0) {
            return item;
          } else {
            const tag = {
              ...item,
              ...{
                disabled: true,
                tooltip: 'This tags has already been added to this board',
              },
            };
            tags.push(tag);
            return tag;
          }
        }) as Array<ITimelineTagMetric>;

        this.form.get('dataSource').setValue(tags);
        this.tagsDataSource = {
          items:
            this.initialState.page > 1
              ? [...this.tagsDataSource?.items, ...dataSource]
              : dataSource,
          totalCount: response.totalCount,
        };

        this.widgetSettingsService.loader$.next(false);
      });
  }

  public onClear(): void {
    this.initialState.query = undefined;
    this.onGetTags();
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, [Validators.required]),
    );
  }
  public onChangeDataSources(tag: ITimelineTagMetric): void {
    this.form.get('name').setValue(tag.name);
    this.form.get('description').setValue(tag.description);
    this.timelineWidget.dataSource = [tag.id];
    this.widgetService.reloadWidget$.next({
      widget: this.timelineWidget,
      dates: this.dates,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.onGetTags();
    this.widgetService.reloadWidget$.next({
      widget: this.timelineWidget,
      dates: this.dates,
    });
  }
}
